//--------------------------------------------------------------
// Imports Section
//--------------------------------------------------------------
import React                            from 'react'

import './ScrollDown.scss'

//--------------------------------------------------------------
// Component Section
//--------------------------------------------------------------
const ScrollDown = (props) => {

    //----------------------------------------------------------
    // Initialization Section
    //----------------------------------------------------------



    //----------------------------------------------------------
    // Render Section
    //----------------------------------------------------------
    return (
        <div className="indicator">

        </div>
    )

}


//--------------------------------------------------------------
// Exports Section
//--------------------------------------------------------------
export default ScrollDown
